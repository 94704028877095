import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ children }) => {
    const currentUser = useSelector((state) => state.auth.currentUser);
    const location = useLocation();

    // Check if there is no current user
    if (!currentUser) {
        // Redirect to the login page, preserving the location they were trying to go to
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    // If there is a current user, render the children components
    return children;
};

export default ProtectedRoute;
