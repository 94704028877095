export const standardMargins = [
  { min: 0, max: 1, margin: 45 },
  { min: 1, max: 3, margin: 29 },
  { min: 3, max: 5, margin: 28 },
  { min: 5, max: 9, margin: 25 },
  { min: 9, max: 12, margin: 23 },
  { min: 12, max: Infinity, margin: 20 }, // Ensure the last max is Infinity
];

export const calculatePrices = (localItem, categoryData) => {
  const costPerUnit = (localItem.packSize && parseFloat(localItem.packSize) > 0)
    ? (localItem.unitPrice || 0) / parseFloat(localItem.packSize)
    : (localItem.unitPrice || 0);

  console.log('Cost per Unit:', costPerUnit);

  const marginRanges = categoryData?.marginRanges && categoryData.marginRanges.length > 0
    ? categoryData.marginRanges
    : standardMargins;

  console.log('Using Margin Ranges:', marginRanges);

  const marginRange = marginRanges.find(({ min, max }) => costPerUnit >= min && costPerUnit <= (max ?? Infinity));
  console.log('Found Margin Range:', marginRange);

  const margin = marginRange ? marginRange.margin : 0;
  console.log('Margin:', margin);

  const markupPrice = costPerUnit / (1 - margin / 100);
  console.log('Markup Price (before rounding):', markupPrice);

  const finalPrice = Math.round(markupPrice * 10) / 10 + 0.09; // Round to nearest 0.09
  console.log('Final Calculated Price:', finalPrice);

  return finalPrice;
};

export const calculatePriceDifference = (currentPrice, calculatedPrice) => {
  const priceDifference = currentPrice ? ((calculatedPrice - currentPrice) / currentPrice) * 100 : 0;
  return priceDifference;
};

export const getRecommendation = (priceDifference) => {
  const recommendation = priceDifference > 6 ? 'increase' : priceDifference < -6 ? 'decrease' : 'keep the same';
  return recommendation;
};
