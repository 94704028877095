import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Avatar, Tooltip, MenuItem, Button, Tabs, Tab } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useAuth } from '../../context/AuthContext';
import { getAuth } from 'firebase/auth';
import logo from '../../assets/FiveSpiceHorizontal.svg';

function ResponsiveAppBar() {
    const { currentUser } = useAuth();
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const navigate = useNavigate();
    const location = useLocation(); // Get current location

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogoutClick = async () => {
        handleCloseUserMenu();
        const auth = getAuth();
        await auth.signOut(); // Log out the user
        navigate('/'); // Redirect to the homepage after logging out
    };

    const handleTabChange = (event, newValue) => {
        navigate(newValue);
    };

    const renderUserSection = () => {
        if (!currentUser) {
            return (
                <Button
                    startIcon={<AccountCircleIcon />}
                    onClick={() => navigate('/login')}
                    variant="outlined"
                    sx={(theme) => ({
                        color: theme.palette.mode === "dark" ? "white" : "black",
                        borderColor: theme.palette.mode === "dark" ? "white" : "black",
                        '&:hover': {
                            backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.08)" : "rgba(0, 0, 0, 0.04)",
                            borderColor: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        fontWeight: 'bold',
                    })}
                >
                    Log In
                </Button>
            );
        }

        const avatarContent = currentUser.photoURL ? (
            <Avatar alt="User" src={currentUser.photoURL} />
        ) : (
            <Avatar>{currentUser.displayName?.charAt(0) ?? 'A'}</Avatar>
        );

        return (
            <React.Fragment>
                <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        {avatarContent}
                    </IconButton>
                </Tooltip>
                <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >
                    <MenuItem onClick={() => navigate('/dashboard')}>
                        <Typography textAlign="center">Your Account</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => navigate('/invoice-entry')}>
                        <Typography textAlign="center">Invoice Entry</Typography>
                    </MenuItem>
                    <MenuItem onClick={handleLogoutClick}>
                        <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                </Menu>
            </React.Fragment>
        );
    };

    return (
        <AppBar position="static" sx={{ backgroundColor: 'white', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box onClick={() => navigate('/dashboard')} sx={{ flexGrow: 1, display: 'flex' }}>
                        <img src={logo} alt="Logo" style={{ maxHeight: '60px', cursor: 'pointer' }} />
                    </Box>
                    {currentUser && (
                        <Tabs
                            value={location.pathname}
                            onChange={handleTabChange}
                            aria-label="navigation tabs"
                            textColor="primary"
                            indicatorColor="primary"
                            sx={{ flexGrow: 1 }}
                        >
                            <Tab label="Home" value="/dashboard" />
                            <Tab label="Invoice Entry" value="/invoice-entry" />
                        </Tabs>
                    )}
                    <Box sx={{ flexGrow: 0 }}>
                        {renderUserSection()}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default ResponsiveAppBar;
