import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { collection, query, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust this import to where your Firebase config is

export const setupInvoiceListener = createAsyncThunk(
  'invoices/setupListener',
  async (_, { dispatch }) => {
    const q = query(collection(db, 'invoices'));
    
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const invoices = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          doc_id: doc.id,
          vendor: data.standardized?.vendorName || 'Unknown Vendor',
          invoiceDate: data.standardized?.invoiceDate,
          invoiceStatus: data.standardized?.invoiceStatus || 'new',
        };
      });
      dispatch(updateInvoices(invoices));
    }, (error) => {
      dispatch(setError(error.message));
    });
    
    return unsubscribe;
  }
);

const invoiceSlice = createSlice({
  name: 'invoices',
  initialState: {
    invoices: [],
    status: 'idle',
    error: null,
    listener: null,
  },
  reducers: {
    updateInvoices: (state, action) => {
      state.invoices = action.payload;
      state.status = 'succeeded';
    },
    setError: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setupInvoiceListener.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(setupInvoiceListener.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listener = action.payload;
      })
      .addCase(setupInvoiceListener.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { updateInvoices, setError } = invoiceSlice.actions;
export const selectAllInvoices = (state) => state.invoices.invoices;

export default invoiceSlice.reducer;