// src/components/InvoiceMainPage/Toolbar.jsx
import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Divider, Toolbar, Tooltip } from '@mui/material';
import ViewListIcon from '@mui/icons-material/ViewList';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SettingsIcon from '@mui/icons-material/Settings';
import CategoryIcon from '@mui/icons-material/Category';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import SearchIcon from '@mui/icons-material/Search';
import ReportIcon from '@mui/icons-material/Report';
import HelpIcon from '@mui/icons-material/Help';
import LocalOfferIcon from '@mui/icons-material/LocalOffer'; // Import an icon for SalesTool
import PersonIcon from '@mui/icons-material/Person';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InvoiceUploadDialog from './InvoiceUploadDialog';

const ToolbarComponent = ({
    isOpen,
    toggleDrawer,
    handleOpenAllInvoices,
    handleOpenSettings,
    handleOpenCategories,
    handleOpenRecentActivities,
    handleOpenSearchInvoices,
    handleOpenGenerateReports,
    handleOpenHelpSupport,
    handleOpenUserProfile,
    handleOpenDashboard,
    onUploadInvoice,
    handleOpenSalesTool,
    api
}) => {
    const [uploadOpen, setUploadOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const handleUploadOpen = () => setUploadOpen(true);
    const handleUploadClose = () => setUploadOpen(false);

    const onDrop = async (acceptedFiles) => {
        setIsLoading(true);
        setError(null);
        setSuccess(null);

        try {
            const response = await api.uploadInvoice(acceptedFiles[0]);
            setSuccess('Invoice uploaded successfully');
            console.log('Upload response:', response);
            setUploadOpen(false);
            onUploadInvoice(); // Refresh the invoices list or perform any needed action after successful upload
        } catch (error) {
            setError('Failed to upload invoice');
            console.error('Upload error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Drawer
                variant="permanent"
                open={isOpen}
                sx={{
                    '& .MuiDrawer-paper': {
                        position: 'relative',
                        width: isOpen ? 240 : 60,
                        transition: 'width 0.3s',
                        overflowX: 'hidden',
                    },
                }}
            >
                <Toolbar>
                    <IconButton onClick={toggleDrawer}>
                        {isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </Toolbar>
                <Divider />
                <List>
                    <Tooltip title="Dashboard" placement="right" arrow>
                        <ListItem button onClick={handleOpenDashboard}>
                            <ListItemIcon><DashboardIcon /></ListItemIcon>
                            {isOpen && <ListItemText primary="Dashboard" />}
                        </ListItem>
                    </Tooltip>
                    <Tooltip title="View All Invoices" placement="right" arrow>
                        <ListItem button onClick={handleOpenAllInvoices}>
                            <ListItemIcon><ViewListIcon /></ListItemIcon>
                            {isOpen && <ListItemText primary="View All Invoices" />}
                        </ListItem>
                    </Tooltip>
                    <Tooltip title="Upload a New Invoice" placement="right" arrow>
                        <ListItem button onClick={handleUploadOpen}>
                            <ListItemIcon><FileUploadIcon /></ListItemIcon>
                            {isOpen && <ListItemText primary="Upload a New Invoice" />}
                        </ListItem>
                    </Tooltip>
                    <Tooltip title="Settings" placement="right" arrow>
                        <ListItem button onClick={handleOpenSettings}>
                            <ListItemIcon><SettingsIcon /></ListItemIcon>
                            {isOpen && <ListItemText primary="Settings" />}
                        </ListItem>
                    </Tooltip>
                    <Tooltip title="View Categories" placement="right" arrow>
                        <ListItem button onClick={handleOpenCategories}>
                            <ListItemIcon><CategoryIcon /></ListItemIcon>
                            {isOpen && <ListItemText primary="View Categories" />}
                        </ListItem>
                    </Tooltip>
                    <Tooltip title="Recent Activities" placement="right" arrow>
                        <ListItem button onClick={handleOpenRecentActivities}>
                            <ListItemIcon><RecentActorsIcon /></ListItemIcon>
                            {isOpen && <ListItemText primary="Recent Activities" />}
                        </ListItem>
                    </Tooltip>
                    <Tooltip title="Search Invoices" placement="right" arrow>
                        <ListItem button onClick={handleOpenSearchInvoices}>
                            <ListItemIcon><SearchIcon /></ListItemIcon>
                            {isOpen && <ListItemText primary="Search Invoices" />}
                        </ListItem>
                    </Tooltip>
                    <Tooltip title="User Profile" placement="right" arrow>
                        <ListItem button onClick={handleOpenUserProfile}>
                            <ListItemIcon><PersonIcon /></ListItemIcon>
                            {isOpen && <ListItemText primary="User Profile" />}
                        </ListItem>
                    </Tooltip>
                    <Tooltip title="Sales Tool" placement="right" arrow>
                    <ListItem button onClick={handleOpenSalesTool}>
                        <ListItemIcon><LocalOfferIcon /></ListItemIcon>
                        {isOpen && <ListItemText primary="Sales Tool" />}
                    </ListItem>
                </Tooltip>
                </List>
            </Drawer>
            <InvoiceUploadDialog
                open={uploadOpen}
                onClose={handleUploadClose}
                onDrop={onDrop}
                isLoading={isLoading}
            />
        </>
    );
};

export default ToolbarComponent;
