// src/components/InvoiceMainPage/CategoryList.jsx
import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';

const CategoryList = ({ categories, onCategoryClick }) => {
  return (
    <List>
      {categories.map((category) => (
        <ListItem button key={category.squareId} onClick={() => onCategoryClick(category)}>
          <ListItemText primary={category.name} />
        </ListItem>
      ))}
    </List>
  );
};

export default CategoryList;
