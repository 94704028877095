import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import ResponsiveAppBar from '../Navbar/ResponsiveAppBar';
import UserGreeting from './UserGreeting/UserGreeting'; 
import { useAuth } from '../../context/AuthContext'; // Import the context
import EmployeeNavigation from './EmployeeNavigation';
import { jwtDecode } from "jwt-decode";

import CreateProductModal from './Modals/CreateProductModal';

const Dashboard = () => {
    const { currentUser } = useAuth(); // Use the context
    const [isEmployee, setIsEmployee] = useState(false);
    const navigate = useNavigate();

    const [showCreateProductModal, setShowCreateProductModal] = useState(false);

    const handleOpenCreateProductModal = () => setShowCreateProductModal(true);
    const handleCloseCreateProductModal = () => setShowCreateProductModal(false);

    useEffect(() => {
        if (!currentUser) {
            navigate('/');
            return;
        }
        if (currentUser && currentUser.accessToken) {
            const decodedToken = jwtDecode(currentUser.accessToken);
            setIsEmployee(decodedToken.isEmployee === true); // Assuming 'isEmployee' is a boolean in the decoded token
        }
    }, [currentUser, navigate]);

    return (
        <div>
            <ResponsiveAppBar />
            <Grid container spacing={2} sx={{ padding: 2, textAlign: 'center' }}>
                <Grid item xs={12}>
                    <Typography variant="h3">Employee Dashboard</Typography>
                </Grid>
                {isEmployee && (
                    <>
                        <Grid item xs={12}>
                            <EmployeeNavigation
                                onOpenCreateProduct={handleOpenCreateProductModal}
                                onOpenCheckPrice={() => { }}
                                onOpenEmployeeDashboard={() => { }}
                                onOpenEmployeeInfo={() => { }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">Welcome, {currentUser.displayName || "Employee"}!</Typography>
                            <Typography variant="body1">Email: {currentUser.email}</Typography>
                            <Typography variant="body1">Phone: {currentUser.phoneNumber || "N/A"}</Typography>
                        </Grid>
                        <CreateProductModal
                            open={showCreateProductModal}
                            onClose={handleCloseCreateProductModal}
                            accessToken={currentUser?.accessToken} // Pass accessToken here
                        />
                    </>
                )}
            </Grid>
        </div>
    );
};

export default Dashboard;
