import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, CircularProgress, Alert, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ResponsiveAppBar from '../Navbar/ResponsiveAppBar';
import { useCustomerData } from '../Dashboard/Hooks/useCustomerData';
import { useAuth } from '../../context/AuthContext';
import createApi from './api';
import ViewManager from './ViewManager';
import ToolbarComponent from './Toolbar';
import Dashboard from './Dashboard';
import UserProfile from './UserProfile';
import { setupInvoiceListener, selectAllInvoices } from '../../redux/invoiceSlice';

const InvoiceMainPage = () => {
    const { currentUser } = useAuth();
    const { customerData } = useCustomerData(currentUser);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const invoices = useSelector(selectAllInvoices);
    const invoiceStatus = useSelector(state => state.invoices.status);
    const error = useSelector(state => state.invoices.error);
    const unsubscribe = useSelector(state => state.invoices.listener);

    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [selectedDocId, setSelectedDocId] = useState(null);
    const [invoiceData, setInvoiceData] = useState(null);
    const [activeView, setActiveView] = useState('dashboard');
    const [isDrawerOpen, setIsDrawerOpen] = useState(true);

    const api = useMemo(() => createApi(currentUser?.stsTokenManager?.accessToken), [currentUser]);

    useEffect(() => {
        if (currentUser && invoiceStatus === 'idle') {
            dispatch(setupInvoiceListener());
        }
        return () => {
            if (typeof unsubscribe === 'function') {
                unsubscribe();
            }
        };
    }, [currentUser, invoiceStatus, dispatch, unsubscribe]);

    useEffect(() => {
        if (!currentUser) {
            navigate('/');
        }
    }, [currentUser, navigate]);

    const fetchInvoiceData = useCallback(async (docId) => {
        try {
            const data = await api.getInvoiceData(docId);
            if (data.standardized.invoiceStatus === 'new') {
                data.standardized.invoiceStatus = 'pending';
                await api.updateInvoiceStatus(docId, 'pending');
            }
            setInvoiceData(data);
        } catch (error) {
            console.error('Failed to fetch invoice data', error);
        }
    }, [api]);

    const handleSelectInvoice = useCallback((invoice) => {
        setSelectedInvoice(invoice);
        setSelectedDocId(invoice.doc_id);
        fetchInvoiceData(invoice.doc_id);
        setActiveView('main');
    }, [fetchInvoiceData]);

    const handleInvoiceDeleted = useCallback(() => {
        setSelectedInvoice(null);
        setSelectedDocId(null);
        setInvoiceData(null);
        setActiveView('dashboard');
    }, []);

    const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

    const viewHandlers = useMemo(() => ({
        handleOpenAllInvoices: () => setActiveView('allInvoices'),
        handleOpenSettings: () => setActiveView('settings'),
        handleOpenCategories: () => setActiveView('categories'),
        handleOpenRecentActivities: () => setActiveView('recentActivities'),
        handleOpenSearchInvoices: () => setActiveView('searchInvoices'),
        handleOpenGenerateReports: () => setActiveView('generateReports'),
        handleOpenHelpSupport: () => setActiveView('helpSupport'),
        handleOpenUserProfile: () => setActiveView('userProfile'),
        handleOpenDashboard: () => setActiveView('dashboard'),
        handleBackToMain: () => setActiveView('main'),
        handleOpenSalesTool: () => setActiveView('salesTool'),
        handleInvoiceDeleted,
    }), [handleInvoiceDeleted]);

    if (!currentUser) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    const renderContent = () => {
        if (invoiceStatus === 'loading') return <CircularProgress />;
        if (invoiceStatus === 'failed') return <Alert severity="error">{error}</Alert>;
        if (activeView === 'dashboard') return <Dashboard invoices={invoices} {...viewHandlers} />;
        if (activeView === 'userProfile') return <UserProfile currentUser={currentUser} customerData={customerData} />;
        return (
            <ViewManager
                activeView={activeView}
                invoices={invoices}
                selectedInvoice={selectedInvoice}
                invoiceData={invoiceData}
                currentUser={currentUser}
                selectedDocId={selectedDocId}
                handleSelectInvoice={handleSelectInvoice}
                {...viewHandlers}
            />
        );
    };

    return (
        <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', bgcolor: 'background.default' }}>
            <ResponsiveAppBar user={customerData} />
            <Box sx={{ display: 'flex', flexGrow: 1, height: '100%' }}>
                <ToolbarComponent
                    isOpen={isDrawerOpen}
                    toggleDrawer={toggleDrawer}
                    api={api}
                    {...viewHandlers}
                />
                <Box sx={{ flexGrow: 1, p: 3 }}>
                    <Container maxWidth="xl" sx={{ flexGrow: 1 }}>
                        {renderContent()}
                    </Container>
                </Box>
            </Box>
        </Box>
    );
};

export default InvoiceMainPage;