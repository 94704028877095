import React, { useState, useCallback } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  TextField, Button
} from '@mui/material';

const AddInvoiceItemDialog = ({ open, onClose, onAddItem, currentUser, api, docId }) => {
  const [description, setDescription] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [unitPrice, setUnitPrice] = useState(0);
  const [packSize, setPackSize] = useState('');
  const [vendorId, setVendorId] = useState('');
  const [barcode, setBarcode] = useState('');

  const handleAddItem = useCallback(async () => {
    const newItem = {
      description: description || '',
      quantity: quantity || 0,
      unitPrice: unitPrice || 0,
      packSize: packSize || '',
      totalPrice: (quantity * unitPrice) || 0,
      squareUp: {
        itemId: '',
        itemName: '',
        variationId: '',
        variationName: '',
        price: 0,
        barcode: ''
      }
    };

    try {
      const addedItem = await api.addItem(docId, newItem);
      console.log('Item added successfully:', addedItem);
      onAddItem(newItem); // Pass the new item back to the parent component
      onClose();
    } catch (error) {
      console.error('Error adding item:', error);
    }
  }, [description, quantity, unitPrice, packSize, vendorId, barcode, api, docId, onAddItem, onClose]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Add Invoice Item</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          margin="dense"
        />
        <TextField
          fullWidth
          label="Quantity"
          type="number"
          value={quantity}
          onChange={(e) => setQuantity(parseFloat(e.target.value))}
          margin="dense"
        />
        <TextField
          fullWidth
          label="Unit Price"
          type="number"
          value={unitPrice}
          onChange={(e) => setUnitPrice(parseFloat(e.target.value))}
          margin="dense"
        />
        <TextField
          fullWidth
          label="Pack Size"
          value={packSize}
          onChange={(e) => setPackSize(e.target.value)}
          margin="dense"
        />
        <TextField
          fullWidth
          label="Vendor ID"
          value={vendorId}
          onChange={(e) => setVendorId(e.target.value)}
          margin="dense"
        />
        <TextField
          fullWidth
          label="Barcode"
          value={barcode}
          onChange={(e) => setBarcode(e.target.value)}
          margin="dense"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
        <Button onClick={handleAddItem} variant="contained" color="primary">Add Item</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddInvoiceItemDialog;
