// src/components/Invoices/ViewManager.jsx
import React from "react";
import {
  Grid,
  Paper,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Settings from "./Views/settings";
import AllInvoicesPage from "./AllInvoicesPage";
import CategoryView from "./Views/categoryView";
import InvoiceDisplay from "./InvoiceDisplay";
import ProductsList from "./InvoiceList/ProductsList";
import Dashboard from "./Dashboard";
import SalesTool from "./SaleTool"; // Import the SalesTool component

const ViewManager = ({
  activeView,
  invoices,
  selectedInvoice,
  invoiceData,
  currentUser,
  selectedDocId,
  handleBackToMain,
  handleSelectInvoice,
  handleCloseSettings,
  handleOpenAllInvoices,
  handleOpenSettings,
  handleOpenCategories,
  fetchInvoices,
}) => {
  // Use Material-UI's theming and responsive utilities
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Render the appropriate view based on activeView state
  const renderView = () => {
    switch (activeView) {
      case "settings":
        return <Settings onClose={handleCloseSettings} />;
      case "allInvoices":
        return (
          <AllInvoicesPage
            invoices={invoices}
            onSelectInvoice={handleSelectInvoice}
          />
        );
      case "categories":
        return (
          <CategoryView currentUser={currentUser} onBack={handleBackToMain} />
        );
        case "salesTool":
          return <SalesTool />; // Add this case for the SalesTool
      default:
        if (selectedInvoice) {
          return (
            <Box sx={{ height: '85vh', display: 'flex', overflow: 'hidden' }}>
              <Grid container spacing={2} sx={{ height: '100%', overflow: 'hidden', p: 2 }}>
                <Grid item xs={12} md={6} sx={{ height: '100%', display: 'flex' }}>
                  <Paper elevation={3} sx={{ flex: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ flex: 1, overflow: 'auto', p: 2 }}>
                      <InvoiceDisplay invoiceData={invoiceData} />
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6} sx={{ height: '100%', display: 'flex' }}>
                  <Paper elevation={3} sx={{ flex: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ flex: 1, overflow: 'auto', p: 2 }}>
                      <ProductsList
                        invoiceData={invoiceData}
                        currentUser={currentUser}
                        docId={selectedDocId}
                        onNavigateToMain={handleBackToMain}
                      />
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          );
        } else {
          return (
            <Dashboard
              handleOpenAllInvoices={handleOpenAllInvoices}
              handleOpenSettings={handleOpenSettings}
              handleOpenCategories={handleOpenCategories}
            />
          );
        }
    }
  };

  return (
    <Box
      sx={{
        height: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {renderView()}
    </Box>
  );
};

export default ViewManager;