import React, { useState, useEffect } from 'react';
import { 
  Box, Typography, TextField, Button, Paper, Avatar, CircularProgress, 
  IconButton, Tooltip, Grid, Tabs, Tab, Snackbar, Alert
} from '@mui/material';
import { 
  PhotoCamera, Person, Email, Phone, Lock, Google, AlternateEmail,
  Save
} from '@mui/icons-material';
import VerifiedIcon from '@mui/icons-material/Verified';
import { 
  updateUserProfile, updateUserEmail, updateUserPassword, 
  linkAuthProvider, linkEmailProvider, sendVerificationEmail, 
  uploadProfilePicture, checkLinkedProviders, unlinkProvider, 
  reauthenticateWithPassword 
} from './firebaseAuthFunctions';
import { GoogleAuthProvider, EmailAuthProvider } from "firebase/auth";

const UserProfile = ({ currentUser, customerData }) => {
    const [name, setName] = useState(currentUser?.displayName || '');
    const [email, setEmail] = useState(currentUser?.email || '');
    const [phone, setPhone] = useState(currentUser?.phoneNumber || '');
    const [address, setAddress] = useState(customerData?.address || '');
    const [newPassword, setNewPassword] = useState('');
    const [profilePicture, setProfilePicture] = useState(currentUser?.photoURL || '');
    const [error, setError] = useState(null);
    const [linkedProviders, setLinkedProviders] = useState({ google: false, email: false });
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        console.log('Current User Data:', currentUser);
        console.log('Customer Data:', customerData);
        setLinkedProviders(checkLinkedProviders());
    }, [currentUser, customerData]);

    const handleSave = async () => {
        try {
            setIsLoading(true);
            if (name !== currentUser.displayName || profilePicture !== currentUser.photoURL) {
                await updateUserProfile(name, profilePicture);
            }
            if (email !== currentUser.email) {
                await updateUserEmail(email);
            }
            if (newPassword) {
                await updateUserPassword(newPassword);
            }
            setSuccessMessage('Profile updated successfully!');
            console.log('User profile information saved successfully.');
        } catch (error) {
            console.error('Error saving user profile information:', error);
            setError('Failed to save user profile information. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleLinkGoogle = async () => {
        try {
            setIsLoading(true);
            await linkAuthProvider(new GoogleAuthProvider());
            setLinkedProviders(checkLinkedProviders());
            console.log('Google account linked successfully.');
        } catch (error) {
            console.error('Error linking Google account:', error);
            setError('Failed to link Google account. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleLinkEmail = async () => {
        try {
            setIsLoading(true);
            const newEmail = prompt('Enter your new email address:');
            const password = prompt('Enter your password:');
            await linkEmailProvider(newEmail, password);
            setLinkedProviders(checkLinkedProviders());
            console.log('Email linked successfully.');
        } catch (error) {
            console.error('Error linking email:', error);
            setError('Failed to link email. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleUnlinkProvider = async (providerId) => {
        try {
            setIsLoading(true);
            const password = prompt('Please enter your password to verify your identity.');
            await reauthenticateWithPassword(password);
            await unlinkProvider(providerId);
            setLinkedProviders(checkLinkedProviders());
            console.log(`${providerId} unlinked successfully.`);
        } catch (error) {
            console.error(`Error unlinking ${providerId}:`, error);
            setError(`Failed to unlink ${providerId}. Please try again.`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSendVerificationEmail = async () => {
        try {
            setIsLoading(true);
            await sendVerificationEmail();
            console.log('Verification email sent successfully.');
        } catch (error) {
            console.error('Error sending verification email:', error);
            setError('Failed to send verification email. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleProfilePictureChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                setIsLoading(true);
                const photoURL = await uploadProfilePicture(file);
                setProfilePicture(photoURL);
                console.log('Profile picture updated successfully.');
            } catch (error) {
                console.error('Error uploading profile picture:', error);
                setError('Failed to upload profile picture. Please try again.');
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>User Profile</Typography>
            <Paper sx={{ p: 3 }}>
                <Tabs value={activeTab} onChange={handleTabChange} centered sx={{ mb: 3 }}>
                    <Tab label="Personal Info" />
                    <Tab label="Security" />
                    <Tab label="Linked Accounts" />
                </Tabs>

                {activeTab === 0 && (
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                                <Avatar src={profilePicture} sx={{ width: 120, height: 120 }} />
                                <Tooltip title="Upload new picture">
                                    <IconButton color="primary" component="label" sx={{ mt: -6, ml: 8 }}>
                                        <input hidden accept="image/*" type="file" onChange={handleProfilePictureChange} />
                                        <PhotoCamera />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <TextField
                                    label="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: <Person sx={{ color: 'action.active', mr: 1 }} />,
                                    }}
                                />
                                <TextField
                                    label="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: <Email sx={{ color: 'action.active', mr: 1 }} />,
                                        endAdornment: currentUser.emailVerified ? (
                                            <Tooltip title="Email Verified">
                                                <VerifiedIcon color="primary" />
                                            </Tooltip>
                                        ) : (
                                            <Button variant="outlined" size="small" onClick={handleSendVerificationEmail}>
                                                Verify
                                            </Button>
                                        ),
                                    }}
                                />
                                <TextField
                                    label="Phone"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    fullWidth
                                    disabled
                                    InputProps={{
                                        startAdornment: <Phone sx={{ color: 'action.active', mr: 1 }} />,
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                )}

                {activeTab === 1 && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <TextField
                            label="New Password"
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            fullWidth
                            InputProps={{
                                startAdornment: <Lock sx={{ color: 'action.active', mr: 1 }} />,
                            }}
                        />
                    </Box>
                )}

                {activeTab === 2 && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Typography variant="h6">Linked Providers</Typography>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Button
                                variant="contained"
                                color={linkedProviders.google ? "success" : "secondary"}
                                onClick={handleLinkGoogle}
                                disabled={linkedProviders.google}
                                startIcon={<Google />}
                            >
                                {linkedProviders.google ? "Google Linked" : "Link Google"}
                            </Button>
                            {linkedProviders.google && (
                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={() => handleUnlinkProvider(GoogleAuthProvider.PROVIDER_ID)}
                                    startIcon={<Google />}
                                >
                                    Unlink Google
                                </Button>
                            )}
                        </Box>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Button
                                variant="contained"
                                color={linkedProviders.email ? "success" : "secondary"}
                                onClick={handleLinkEmail}
                                disabled={linkedProviders.email}
                                startIcon={<AlternateEmail />}
                            >
                                {linkedProviders.email ? "Email Linked" : "Link Email"}
                            </Button>
                            {linkedProviders.email && (
                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={() => handleUnlinkProvider(EmailAuthProvider.PROVIDER_ID)}
                                    startIcon={<AlternateEmail />}
                                >
                                    Unlink Email
                                </Button>
                            )}
                        </Box>
                    </Box>
                )}

                {error && (
                    <Typography color="error" variant="body2" sx={{ mt: 2 }}>
                        {error}
                    </Typography>
                )}

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    disabled={isLoading}
                    sx={{ mt: 3 }}
                    startIcon={isLoading ? <CircularProgress size={24} /> : <Save />}
                >
                    {isLoading ? 'Saving...' : 'Save Changes'}
                </Button>
            </Paper>

            <Snackbar
                open={!!successMessage}
                autoHideDuration={6000}
                onClose={() => setSuccessMessage('')}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default UserProfile;