import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import HomePage from './components/HomePage/HomePage';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';
import NewCustomerPage from './components/NewCustomer/newCustomerPage';
import SiteUpdate from './components/site-updates/SiteUpdates';
import ProtectedRoute from './components/ProtectedRoute';
import CostCalculator from './components/CostCalculatorFolder/CostCalculator';
import { CustomerExistenceProvider } from './context/CustomerExistenceContext';
import InvoiceEntry from './components/Invoices/invoiceMainPage';
import ProduceDisplay from './components/ProduceDisplay/produceDisplay';
import Modal from 'react-modal';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { fetchUserToken, setUser } from './redux/authSlice';
import { getAuth, onAuthStateChanged, setPersistence, browserSessionPersistence } from 'firebase/auth';
import './firebase';

Modal.setAppElement('#root');

function App() {
  const dispatch = useDispatch();
  const auth = getAuth();
  const { status } = useSelector((state) => state.auth);

  useEffect(() => {
    setPersistence(auth, browserSessionPersistence)
      .then(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          dispatch(setUser(user));
          if (user) {
            dispatch(fetchUserToken());
          }
        });

        return () => unsubscribe();
      })
      .catch((error) => {
        console.error('Failed to set persistence:', error);
      });
  }, [auth, dispatch]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(fetchUserToken());
    }, 60 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [dispatch]);

  if (status === 'loading') {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <CustomerExistenceProvider>
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/siteupdates" element={<SiteUpdate />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            } />
            <Route path="/new-customer" element={
              <ProtectedRoute>
                <NewCustomerPage />
              </ProtectedRoute>
            } />
            <Route path="/cost-calculator" element={
              <ProtectedRoute>
                <CostCalculator />
              </ProtectedRoute>
            } />
            <Route path="/invoice-entry" element={
              <ProtectedRoute>
                <InvoiceEntry />
              </ProtectedRoute>
            } />
            <Route path="/produce" element={<ProduceDisplay />} />
          </Routes>
        </Router>
      </CustomerExistenceProvider>
    </ThemeProvider>
  );
}

export default App;
