import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  Paper,
  Grid,
  Divider
} from '@mui/material';

const Settings = ({ onClose }) => {
  const [settings, setSettings] = useState({
    defaultMailbox: '',
    autoProcessInvoices: true,
    notifyOnNewInvoice: true,
    squareApiKey: '',
    emailNotifications: ''
  });

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    setSettings(prevSettings => ({
      ...prevSettings,
      [name]: event.target.type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Here you would typically send the settings to your backend
    console.log('Settings submitted:', settings);
    // Optionally close settings after saving
    onClose();
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Invoice Management Settings
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Default Mailbox"
                name="defaultMailbox"
                value={settings.defaultMailbox}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={settings.autoProcessInvoices}
                    onChange={handleChange}
                    name="autoProcessInvoices"
                  />
                }
                label="Automatically process new invoices"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={settings.notifyOnNewInvoice}
                    onChange={handleChange}
                    name="notifyOnNewInvoice"
                  />
                }
                label="Notify me when new invoices arrive"
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Integration Settings
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Square API Key"
                name="squareApiKey"
                value={settings.squareApiKey}
                onChange={handleChange}
                type="password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email for notifications"
                name="emailNotifications"
                value={settings.emailNotifications}
                onChange={handleChange}
                type="email"
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button variant="contained" color="primary" type="submit">
                  Save Settings
                </Button>
                <Button variant="outlined" color="secondary" onClick={onClose}>
                  Close
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
};

export default Settings;