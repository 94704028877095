import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  IconButton,
  Autocomplete,
  Divider,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import createApi from './api'; // Make sure this path is correct

const SalesTool = ({ token }) => {
  const [saleName, setSaleName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [headerInfo, setHeaderInfo] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [saleItems, setSaleItems] = useState([]);
  const [api, setApi] = useState(null);
  const [openFlyer, setOpenFlyer] = useState(false);

  useEffect(() => {
    const apiInstance = createApi(token);
    setApi(apiInstance);
  }, [token]);

  const handleSearch = async () => {
    if (api && searchQuery) {
      try {
        const results = await api.searchSquareProducts(searchQuery);
        setSearchResults(results);
      } catch (error) {
        console.error('Error searching products:', error);
      }
    }
  };

  const handleAddItem = (item) => {
    setSaleItems([...saleItems, { ...item, salePrice: item.price }]);
    setSearchQuery('');
    setSearchResults([]);
  };

  const handleSalePriceChange = (index, newPrice) => {
    const updatedItems = [...saleItems];
    updatedItems[index].salePrice = newPrice;
    setSaleItems(updatedItems);
  };

  const handleRemoveItem = (index) => {
    setSaleItems(saleItems.filter((_, i) => i !== index));
  };

  const handleGenerateFlyer = () => {
    setOpenFlyer(true);
  };

  const FlyerPreview = () => {
    const itemsPerPage = 20;
    const pages = Math.ceil(saleItems.length / itemsPerPage);

    return (
      <Dialog open={openFlyer} onClose={() => setOpenFlyer(false)} maxWidth="md" fullWidth>
        <DialogTitle>Sales Flyer Preview</DialogTitle>
        <DialogContent>
          {Array.from({ length: pages }, (_, pageIndex) => (
            <Paper key={pageIndex} elevation={3} sx={{ p: 3, mb: 2, pageBreakAfter: 'always' }}>
              <Typography variant="h4" align="center" gutterBottom>
                {saleName}
              </Typography>
              <Typography variant="h6" align="center" gutterBottom>
                {startDate} to {endDate}
              </Typography>
              <Typography variant="body1" align="center" paragraph>
                {headerInfo}
              </Typography>
              <Grid container spacing={2}>
                {saleItems.slice(pageIndex * itemsPerPage, (pageIndex + 1) * itemsPerPage).map((item, index) => (
                  <Grid item xs={6} key={item.variationId}>
                    <Box display="flex" alignItems="center">
                      <Avatar src={item.imageUrls[0]} alt={item.itemName} sx={{ mr: 2 }} />
                      <Box>
                        <Typography variant="subtitle1">{item.itemName}</Typography>
                        <Typography variant="body2">{item.variationName}</Typography>
                        <Typography variant="h6">${item.salePrice.toFixed(2)}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          Original: ${item.price.toFixed(2)}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                While Supplies Last. Prices and promotions may change without notice. No Rainchecks.
              </Typography>
            </Paper>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFlyer(false)}>Close</Button>
          <Button variant="contained" onClick={() => window.print()}>Print Flyer</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Box sx={{ display: 'flex', p: 3 }}>
      {/* Flyer Settings and Preview */}
      <Paper elevation={3} sx={{ width: '50%', p: 3, mr: 2 }}>
        <Typography variant="h5" gutterBottom>
          Flyer Settings
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Sale Name"
              value={saleName}
              onChange={(e) => setSaleName(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Start Date"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="End Date"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Header Information"
              multiline
              rows={3}
              value={headerInfo}
              onChange={(e) => setHeaderInfo(e.target.value)}
            />
          </Grid>
        </Grid>
        <Button variant="contained" onClick={handleGenerateFlyer} sx={{ mt: 2 }}>
          Generate Flyer
        </Button>
        <FlyerPreview />
      </Paper>

      {/* Search and Sale Items Section */}
      <Paper elevation={3} sx={{ width: '50%', p: 3 }}>
        <Typography variant="h5" gutterBottom>
          Add Sale Items
        </Typography>
        <Autocomplete
          fullWidth
          options={searchResults}
          getOptionLabel={(option) => `${option.itemName} - ${option.variationName} ($${option.price.toFixed(2)})`}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Products"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleSearch}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
          onChange={(_, value) => value && handleAddItem(value)}
          value={null}
        />
        <Divider sx={{ my: 2 }} />
        <Typography variant="h6" gutterBottom>
          Sale Items
        </Typography>
        <List>
          {saleItems.map((item, index) => (
            <ListItem
              key={item.variationId}
              secondaryAction={
                <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveItem(index)}>
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar src={item.imageUrls[0]} alt={item.itemName} />
              </ListItemAvatar>
              <ListItemText
                primary={`${item.itemName} - ${item.variationName}`}
                secondary={`SKU: ${item.sku} | UPC: ${item.upc}`}
              />
              <TextField
                label="Sale Price"
                type="number"
                value={item.salePrice}
                onChange={(e) => handleSalePriceChange(index, parseFloat(e.target.value))}
                InputProps={{ inputProps: { min: 0, step: 0.01 } }}
                sx={{ width: 100, ml: 2 }}
              />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Box>
  );
};

export default SalesTool;