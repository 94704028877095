import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Typography, Grid, Box, TextField, Button, Card, CardContent, InputAdornment, Divider, Snackbar, Alert, Tooltip, Paper
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import createApi from '../api';
import { calculatePrices, calculatePriceDifference, getRecommendation, standardMargins } from './priceCalculator';

const categoryCache = {};

const ProductItem = React.memo(({ item, invoiceId, itemIndex, onSearch, onUpdate, onDelete, disabled, isLoading, currentUser }) => {
  const [localItem, setLocalItem] = useState(item || {});
  const [categoryData, setCategoryData] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [localPrice, setLocalPrice] = useState(item?.squareUp?.price || 0);
  const [calculatedPrice, setCalculatedPrice] = useState(null);

  const api = useMemo(() => createApi(currentUser?.stsTokenManager?.accessToken), [currentUser]);

  useEffect(() => {
    setLocalItem(prevLocalItem => ({
      ...item,
      packSize: prevLocalItem.packSize || item.packSize,
    }));
    setLocalPrice(item?.squareUp?.price ? item.squareUp.price : 0);
  }, [item]);

  const fetchCategoryData = useCallback(async () => {
    if (!localItem?.squareUp?.categoryId || localItem.squareUp.categoryId === 'N/A') {
      setCategoryData({ marginRanges: standardMargins });
      return;
    }
  
    const categoryId = localItem.squareUp.categoryId;
    if (categoryCache[categoryId]) {
      setCategoryData(categoryCache[categoryId]);
    } else {
      try {
        const category = await api.getCategoryById(categoryId);
        categoryCache[categoryId] = category;
        setCategoryData(category);
      } catch (error) {
        console.error('Error fetching category data:', error);
      }
    }
  }, [localItem?.squareUp?.categoryId, api]);
  
  useEffect(() => {
    fetchCategoryData();
  }, [fetchCategoryData]);
  
  const handleInputChange = useCallback((field, isSquareUpdate = false) => (event) => {
    const value = event.target.type === 'number' ? parseFloat(event.target.value) : event.target.value;
    setLocalItem(prev => {
      if (isSquareUpdate) {
        return { ...prev, squareUp: { ...prev.squareUp, [field]: value } };
      }
      return { ...prev, [field]: value };
    });
  }, []);

  const handleSave = useCallback(async () => {
    if (disabled) return;

    const missingFields = [];
    if (!localItem.description) missingFields.push('Description');
    if (localItem.quantity == null) missingFields.push('Quantity');
    if (localItem.unitPrice == null) missingFields.push('Unit Price');
    if (!localItem.packSize) missingFields.push('Pack Size');

    if (missingFields.length > 0) {
      setSnackbarMessage(`Please fill out the following fields: ${missingFields.join(', ')}`);
      setSnackbarOpen(true);
      return;
    }

    try {
      const updatedItemData = {
        ...localItem,
        squareUp: {
          ...localItem.squareUp,
          price: localPrice
        }
      };

      await onUpdate({ invoiceId, itemIndex, updatedItemData });
      setSnackbarMessage('Item updated successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error updating item:', error);
      setSnackbarMessage('Failed to update item. Please try again.');
      setSnackbarOpen(true);
    }
  }, [disabled, localItem, localPrice, onUpdate, invoiceId, itemIndex]);

  const handleMatch = useCallback(async () => {
    console.log(`ProductItem ${itemIndex} handleMatch: Starting match process`);
    console.log(`ProductItem ${itemIndex} handleMatch: Current localItem:`, localItem);
  
    const searchResult = await onSearch({
      invoiceId,
      itemIndex,
      initialSearchTerm: localItem.squareUp?.barcode || localItem.description,
      currentPackSize: localItem.packSize
    });
  
    console.log(`ProductItem ${itemIndex} handleMatch: Search result:`, searchResult);
  
    if (searchResult) {
      console.log(`ProductItem ${itemIndex} handleMatch: Updating localItem`);
      setLocalItem(prev => {
        const updatedItem = {
          ...prev,
          ...searchResult,
          packSize: prev.packSize || searchResult.packSize
        };
        console.log(`ProductItem ${itemIndex} handleMatch: Previous packSize:`, prev.packSize);
        console.log(`ProductItem ${itemIndex} handleMatch: Search result packSize:`, searchResult.packSize);
        console.log(`ProductItem ${itemIndex} handleMatch: Updated localItem:`, updatedItem);
        return updatedItem;
      });
    } else {
      console.log(`ProductItem ${itemIndex} handleMatch: No search result found`);
    }
  }, [onSearch, invoiceId, itemIndex, localItem.squareUp?.barcode, localItem.description, localItem.packSize]);

  const formatPrice = useCallback((price) => {
    if (price < 100) {
      return `0.${price.toString().padStart(2, "0")}`;
    } else {
      const dollars = Math.floor(price / 100);
      const cents = price % 100;
      return `${dollars}.${cents.toString().padStart(2, "0")}`;
    }
  }, []);

  const costPerUnit = useMemo(() => {
    return (localItem.packSize && parseFloat(localItem.packSize) > 0)
      ? (localItem.unitPrice || 0) / parseFloat(localItem.packSize)
      : (localItem.unitPrice || 0);
  }, [localItem.packSize, localItem.unitPrice]);

  const costPerPiece = useMemo(() => {
    return localItem.unitPrice / parseFloat(localItem.packSize) || 0;
  }, [localItem.unitPrice, localItem.packSize]);

  const handleTooltipOpen = useCallback(() => {
    const price = calculatePrices(localItem, categoryData);
    setCalculatedPrice(price);
  }, [localItem, categoryData]);

  const squarePriceInDollars = useMemo(() => localItem.squareUp?.price ? localItem.squareUp.price / 100 : null, [localItem.squareUp?.price]);
  const priceDifference = useMemo(() => calculatePriceDifference(squarePriceInDollars, calculatedPrice), [squarePriceInDollars, calculatedPrice]);
  const recommendation = useMemo(() => getRecommendation(priceDifference), [priceDifference]);

  const hasSquareMatch = useMemo(() => localItem?.squareUp?.itemId && localItem?.squareUp?.variationId, [localItem?.squareUp]);

  const isItemComplete = useMemo(() => localItem.description && localItem.quantity != null &&
    localItem.unitPrice != null && localItem.packSize && hasSquareMatch,
    [localItem, hasSquareMatch]);

  const margin = useMemo(() => ((localPrice / 100 - costPerPiece) / (localPrice / 100)) * 100, [localPrice, costPerPiece]);
  const markup = useMemo(() => ((localPrice / 100 - costPerPiece) / costPerPiece) * 100, [localPrice, costPerPiece]);

  const editableFieldStyle = {
    '&:hover': {
      backgroundColor: '#f0f0f0',
      cursor: 'pointer',
    },
  };

  const buttonStyle = {
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: '4px',
    padding: '6px 12px',
  };

  return (
    <Card elevation={3} sx={{ mb: 2, opacity: disabled ? 0.7 : 1, position: 'relative', maxWidth: { xs: '100%', sm: '600px' }, width: '100%', margin: 'auto' }}>
      {isItemComplete && (
        <FiberManualRecordIcon sx={{ position: 'absolute', top: 8, left: 8, color: 'green', fontSize: 12 }} />
      )}
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={8}>
            <Typography variant="h6" noWrap>{localItem.description || ''}</Typography>
            <Typography variant="body2" color="textSecondary">
              {localItem.squareUp?.barcode && `Barcode: ${localItem.squareUp.barcode}`}
            </Typography>
            {hasSquareMatch ? (
              <Typography variant="caption" color="textSecondary">
                Item Name: {localItem.squareUp?.itemName || ''} || Variation Name: {localItem.squareUp?.variationName || ''}
              </Typography>
            ) : (
              <Typography variant="caption" color="textSecondary">
                No square Item attached
              </Typography>
            )}
          </Grid>
          <Grid item xs={4}>
            <Paper elevation={2} sx={{ p: 1, backgroundColor: '#f0f0f0' }}>
              <Typography component="div" noWrap>
                <strong>Total:</strong> ${(localItem.quantity * localItem.unitPrice).toFixed(2)}
              </Typography>
              <Typography component="div" noWrap>
                <strong>Unit:</strong> ${costPerUnit.toFixed(2)}
              </Typography>
              <Typography component="div" noWrap>
                <strong>Piece:</strong> ${costPerPiece.toFixed(2)}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
        <Divider sx={{ my: 2 }} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Quantity"
              type="number"
              value={localItem.quantity || 0}
              onChange={handleInputChange('quantity')}
              disabled={disabled}
              fullWidth
              size="small"
              sx={editableFieldStyle}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Pack Size"
              value={localItem.packSize || ''}
              onChange={handleInputChange('packSize')}
              disabled={disabled}
              fullWidth
              size="small"
              sx={editableFieldStyle}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Unit Price"
              type="number"
              value={localItem.unitPrice || 0}
              onChange={handleInputChange('unitPrice')}
              disabled={disabled}
              fullWidth
              size="small"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
              sx={editableFieldStyle}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Tooltip
              title={`Margin: ${margin.toFixed(2)}% | Markup: ${markup.toFixed(2)}% | Calculated Price: ${calculatedPrice !== null ? `$${calculatedPrice.toFixed(2)}` : 'Loading...'}`}
              onOpen={handleTooltipOpen}
            >
              <TextField
                label="Sale Price"
                type="text"
                value={localPrice !== '' ? formatPrice(localPrice) : ''}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, '');
                  setLocalPrice(value ? Number(value) : '');
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
                disabled={disabled}
                fullWidth
                size="small"
                sx={editableFieldStyle}
              />
            </Tooltip>
          </Grid>
        </Grid>
        <Box mt={2} display="flex" justifyContent="flex-end" gap={1}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={handleSave}
            disabled={disabled || isLoading}
            sx={buttonStyle}
          >
            Save Changes
          </Button>
          <Button
            variant="outlined"
            startIcon={<SearchIcon />}
            onClick={handleMatch}
            disabled={disabled}
            sx={buttonStyle}
          >
            Match
          </Button>
          <Button
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={() => onDelete({ invoiceId, itemIndex })}
            disabled={disabled}
            sx={buttonStyle}
          >
            Delete
          </Button>
        </Box>
      </CardContent>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarMessage.includes('successfully') ? 'success' : 'error'} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Card>
  );
});

export default ProductItem;