import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import invoiceReducer from './invoiceSlice';
import itemReducer from './itemSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    invoices: invoiceReducer,
    items: itemReducer,
  },
});

export default store;
