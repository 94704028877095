import React, { useState, useCallback, useEffect } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    TextField, Button, CircularProgress, List, ListItem, ListItemText, Typography, Divider, Box, Alert
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import createApi from './api';

const MatchSquareProductDialog = ({ open, onClose, onMatchProduct, currentUser, initialSearchTerm, currentPackSize }) => {
    const [searchTerm, setSearchTerm] = useState(initialSearchTerm || '');
    const [isSearching, setIsSearching] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [error, setError] = useState(null);

    const api = createApi(currentUser?.stsTokenManager?.accessToken);

    useEffect(() => {
        setSearchTerm(initialSearchTerm || '');
    }, [initialSearchTerm]);

    const handleSearch = useCallback(async () => {
        setError(null);
        setIsSearching(true);
        try {
            const results = await api.searchSquareProducts(searchTerm);
            setSearchResults(results);
        } catch (err) {
            console.error('Error searching Square products:', err);
            setError('Failed to search products. Please try again.');
        } finally {
            setIsSearching(false);
        }
    }, [api, searchTerm]);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>Match Square Product</DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    label="Search Term"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyPress={handleKeyPress}
                    margin="dense"
                />
                <Button
                    variant="contained"
                    startIcon={<SearchIcon />}
                    onClick={handleSearch}
                    disabled={isSearching}
                    sx={{ mt: 2 }}
                >
                    {isSearching ? <CircularProgress size={24} /> : 'Search Square Products'}
                </Button>
                {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
                <List>
                    {searchResults.map((result, index) => (
                        <React.Fragment key={index}>
                            <ListItem button onClick={() => onMatchProduct(result, currentPackSize)}>
                                <ListItemText
                                    primary={<Typography variant="subtitle1">{result.name}</Typography>}
                                    secondary={
                                        <>
                                            <Typography variant="body2" color="textPrimary">
                                                {result.variations[0]?.name || ''} - ${result.variations[0]?.price_money?.amount / 100 || 0}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                SKU: {result.sku}
                                            </Typography>
                                        </>
                                    }
                                />
                            </ListItem>
                            <Divider />
                        </React.Fragment>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default MatchSquareProductDialog;
