import axios from 'axios';

const createApi = (token) => {
  const api = axios.create({
    baseURL: 'https://api.fivespiceindiangrocery.com/api',
    headers: {
      'authorization': `${token}`
    }
  });

  return {
    getMailboxes: async () => {
      try {
        const response = await api.get('/mailboxes');
        console.log('Mailboxes data:', response.data);
        return response.data;
      } catch (error) {
        console.error('Error fetching mailboxes:', error.response?.data || error.message);
        throw error;
      }
    },

    getDocuments: async (mailboxId) => {
      try {
        const response = await api.get(`/mailboxes/${mailboxId}/docs`);
        console.log('Documents data:', response.data);
        return response.data.docs;
      } catch (error) {
        console.error('Error fetching documents:', error.response?.data || error.message);
        throw error;
      }
    },

    getAllInvoices: async () => {
      try {
        const response = await api.get('/parsedinvoices');
        console.log('All parsed invoices:', response.data);
        return response.data;
      } catch (error) {
        console.error('Error fetching all parsed invoices:', error.response?.data || error.message);
        throw error;
      }
    },

    getInvoiceData: async (docId) => {
      try {
        const response = await api.get(`/parsedinvoices/${docId}`);
        console.log('Invoice data:', response.data);
        return response.data;
      } catch (error) {
        console.error('Error fetching invoice data:', error.response?.data || error.message);
        throw error;
      }
    },

    searchSquareProducts: async (query) => {
      try {
        const response = await api.get('searchproducts', { params: { query } });
        console.log('Square products search results:', response.data);
        // Ensure each result has a variations array
        const formattedResults = response.data.map(item => ({
          ...item,
          variations: item.variations || [{
            id: item.variationId,
            name: item.variationName,
            price_money: {
              amount: item.price * 100,
              currency: 'USD'
            }
          }]
        }));
        return formattedResults;
      } catch (error) {
        console.error('Error searching Square products:', error.response?.data || error.message);
        throw error;
      }
    },
    getCategories: async (limit = 10) => {
      try {
        const response = await api.get('/Category/List', { params: { limit } });
        console.log('Categories data:', response.data);
        return response.data;
      } catch (error) {
        console.error('Error fetching categories:', error.response?.data || error.message);
        throw error;
      }
    },

    getCategoryById: async (id) => {
      try {
        const response = await api.get(`/Category/Retrieve/${id}`);
        console.log('Category data:', response.data);
        return response.data;
      } catch (error) {
        console.error('Error fetching category:', error.response?.data || error.message);
        throw error;
      }
    },

    updateCategoryMarginRange: async (categoryId, marginRanges) => {
      try {
        const response = await api.post('/Category/UpdateMarginRange', { categoryId, marginRanges });
        console.log('Updated margin ranges:', response.data);
        return response.data;
      } catch (error) {
        console.error('Error updating margin ranges:', error.response?.data || error.message);
        throw error;
      }
    },

    uploadInvoice: async (file) => {
      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await api.post('/upload-invoice', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log('Invoice uploaded successfully:', response.data);
        return response.data;
      } catch (error) {
        console.error('Error uploading invoice:', error.response?.data || error.message);
        throw error;
      }
    },
    deleteInvoice: async (docId) => {
      try {
        const response = await api.delete(`/parsedinvoices/${docId}`);
        console.log('Document deleted:', response.data);
        return response.data;
      } catch (error) {
        console.error('Error deleting document:', error.response?.data || error.message);
        throw error;
      }
    },

    linkSquareProduct: async (invoiceItemId, squareProductId) => {
      try {
        const response = await api.post(`/invoiceitems/${invoiceItemId}/link`, { squareProductId });
        console.log('Linked Square product:', response.data);
        return response.data;
      } catch (error) {
        console.error('Error linking Square product:', error.response?.data || error.message);
        throw error;
      }
    },

    updateItem: async (docId, itemIndex, updatedItemData) => {
      console.log('updateItem called with:', { docId, itemIndex, updatedItemData });

      try {
        const response = await api.post('/update-invoice-item', {
          invoiceId: docId,
          itemIndex,
          updatedItemData: {
            description: updatedItemData.description,
            vendorId: updatedItemData.vendorId,
            barcode: updatedItemData.barcode,
            quantity: updatedItemData.quantity,
            unitPrice: updatedItemData.unitPrice,
            totalPrice: updatedItemData.totalPrice,
            packSize: updatedItemData.packSize,
            weight: updatedItemData.weight,
            unit: updatedItemData.unit,
            squareUp: {
              categoryId: updatedItemData.squareUp?.categoryId || null,
              itemId: updatedItemData.squareUp.itemId,
              variationId: updatedItemData.squareUp.variationId,
              itemName: updatedItemData.squareUp.itemName,
              variationName: updatedItemData.squareUp.variationName,
              price: updatedItemData.squareUp.price,
              barcode: updatedItemData.squareUp.barcode
            }
          }
        });

        console.log('Updated item response:', response.data);
        return response.data;
      } catch (error) {
        console.error('Error updating item:', error.response?.data || error.message);
        throw error;
      }
    },

    updateProductPrice: async (variationId, price) => {
      try {
        const response = await api.post(`/updateproduct/${variationId}`, { price });
        console.log('Updated product price:', response.data);
        return response.data;
      } catch (error) {
        console.error('Error updating product price:', error.response?.data || error.message);
        throw error;
      }
    },

    submitInvoiceToSquare: async (invoiceData, currentUser) => {
      console.log('Submitting invoice to Square:', invoiceData);
      console.log("submitted By Who:", currentUser.displayName)
      try {
        const response = await api.post('/submit-to-square', invoiceData);
        console.log('Submit to Square response:', response.data);
        return response.data;
      } catch (error) {
        console.error('Error submitting invoice to Square:', error.response?.data || error.message);
        throw error;
      }
    },

    updateInvoiceStatus: async (docId, newStatus) => {
      console.log('updateInvoiceStatus called with:', { docId, newStatus });
      try {
        const response = await api.put(`/invoices/${docId}/status`, { status: newStatus });
        console.log('Updated invoice status response:', response.data);
        return response.data;
      } catch (error) {
        console.error('Error updating invoice status:', error.response?.data || error.message);
        throw error;
      }
    },

    // New function for adding an invoice item
    addItem: async (docId, newItemData) => {
      console.log('addItem called with:', { docId, newItemData });

      try {
          const response = await api.post('/add-invoice-item', {
              invoiceId: docId,
              newItemData
          });

          console.log('Added item response:', response.data);
          return response.data;
      } catch (error) {
          console.error('Error adding item:', error.response?.data || error.message);
          throw error;
      }
  },

    // New function for deleting an invoice item
    deleteItem: async (docId, itemIndex) => {
      console.log('deleteItem called with:', { docId, itemIndex });

      try {
        const response = await api.delete('/delete-invoice-item', {
          data: {
            invoiceId: docId,
            itemIndex
          }
        });

        console.log('Deleted item response:', response.data);
        return response.data;
      } catch (error) {
        console.error('Error deleting item:', error.response?.data || error.message);
        throw error;
      }
    },
    automatedMatch: async (itemId, textQuery) => {
      try {
        console.log("This is the ItemID", itemId, "this is the text query", textQuery)
        const response = await api.post('/automated-match', { itemId, textQuery });
        console.log('Automated match result:', response.data);
        return response.data;
      } catch (error) {
        console.error('Error fetching automated match:', error.response?.data || error.message);
        throw error;
      }
    },
  };
};

export default createApi;
