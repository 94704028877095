import React, { useState } from 'react';
import {
   Tabs, Tab, List, ListItem, ListItemText, Divider, Typography,
   Paper, Box, Chip, IconButton
} from '@mui/material';
import { Add as AddIcon, Check as CheckIcon, HourglassEmpty as PendingIcon } from '@mui/icons-material';

const AllInvoicesPage = ({ invoices, onSelectInvoice }) => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const getInvoicesByStatus = (status) => {
    return invoices.filter(invoice => invoice.invoiceStatus === status);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric',
      timeZone: 'UTC'
    });
  };
  const renderInvoiceList = (invoices) => (
    <List>
      {invoices.map(invoice => (
        <Paper elevation={invoice.invoiceStatus === 'new' ? 3 : 1} key={invoice.doc_id} style={{ margin: '10px 0' }}>
          <ListItem button onClick={() => onSelectInvoice(invoice)}>
            <ListItemText
              primary={
                <Typography variant="subtitle1" component="div">
                  {invoice.vendor}
                  <Chip
                    icon={getStatusIcon(invoice.invoiceStatus)}
                    label={invoice.invoiceStatus.toUpperCase()}
                    size="small"
                    color={getStatusColor(invoice.invoiceStatus)}
                    style={{ marginLeft: '10px' }}
                  />
                </Typography>
              }
              secondary={
                <React.Fragment>
                  <Typography variant="body2" component="span">
                    Date: {formatDate(invoice.invoiceDate)}
                  </Typography>
                  <br />
                  <Typography variant="body2" component="span">
                    Amount: ${invoice.amount}
                  </Typography>
                </React.Fragment>
              }
            />
            <IconButton edge="end" aria-label="select">
              <AddIcon />
            </IconButton>
          </ListItem>
        </Paper>
      ))}
    </List>
  );

  const getStatusIcon = (status) => {
    switch (status) {
      case 'new': return <AddIcon />;
      case 'pending': return <PendingIcon />;
      case 'done': return <CheckIcon />;
      default: return null;
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'new': return 'primary';
      case 'pending': return 'warning';
      case 'done': return 'success';
      default: return 'default';
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={currentTab} onChange={handleTabChange} centered>
        <Tab label="New" />
        <Tab label="Pending" />
        <Tab label="Done" />
      </Tabs>
      <Box sx={{ padding: 2 }}>
        {currentTab === 0 && renderInvoiceList(getInvoicesByStatus('new'))}
        {currentTab === 1 && renderInvoiceList(getInvoicesByStatus('pending'))}
        {currentTab === 2 && renderInvoiceList(getInvoicesByStatus('done'))}
      </Box>
    </Box>
  );
};

export default AllInvoicesPage;