import React, { useState, useRef, useEffect } from 'react';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber, signInWithCredential, PhoneAuthProvider } from 'firebase/auth';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Container, TextField, Button, Typography, Box, Alert, InputAdornment, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';
import axios from 'axios';

const Login = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [code, setCode] = useState('');
    const [verificationId, setVerificationId] = useState('');
    const [error, setError] = useState('');
    const [isEmployee, setIsEmployee] = useState(false);
    const [openCompleteProfile, setOpenCompleteProfile] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const auth = getAuth();
    const recaptchaVerifierRef = useRef(null);

    const handlePhoneNumberChange = (value) => {
        const formattedNumber = value.startsWith('+') ? value : `+${value}`;
        setPhoneNumber(formattedNumber);
    };

    useEffect(() => {
        if (currentUser) {
            navigate('/dashboard');
        }
    }, [currentUser, navigate]);

    useEffect(() => {
        recaptchaVerifierRef.current = new RecaptchaVerifier(auth, 'recaptcha-container', {
            'size': 'invisible',
        });
    }, [auth]);

    const handleSendCode = async () => {
        setError('');
        setIsEmployee(false);

        if (phoneNumber.length < 12 || phoneNumber.length > 14) {
            setError('Please enter a valid phone number.');
            return;
        }

        try {
            const response = await axios.get(`https://api.fivespiceindiangrocery.com/api/isEmployee?phoneNumber=${encodeURIComponent(phoneNumber)}`);
            if (response.data.isEmployee) {
                setIsEmployee(true);
                const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifierRef.current);
                setVerificationId(confirmationResult.verificationId);
            } else {
                setError('This phone number is not associated with an employee account.');
            }
        } catch (error) {
            console.error("Error verifying employee status", error);
            setError('Failed to verify employee status. Please try again.');
        }
    };

    const handleVerifyCode = async () => {
        try {
            const credential = PhoneAuthProvider.credential(verificationId, code);
            await signInWithCredential(auth, credential);
            const user = auth.currentUser;
            if (!user.displayName || !user.email) {
                setOpenCompleteProfile(true);
            } else {
                navigate('/dashboard');
            }
        } catch (error) {
            console.error("Error during verification", error);
            setError('Verification failed. Please enter the correct code.');
        }
    };

    const handleCompleteProfileSave = async () => {
        try {
            const user = auth.currentUser;
            if (name) {
                await user.updateProfile({ displayName: name });
            }
            if (email) {
                await user.updateEmail(email);
            }
            if (password) {
                await user.updatePassword(password);
            }
            setOpenCompleteProfile(false);
            navigate('/dashboard');
        } catch (error) {
            console.error("Error updating profile", error);
            setError('Failed to update profile. Please try again.');
        }
    };

    const handleKeyDown = (event, callback) => {
        if (event.key === 'Enter') {
            callback();
        }
    };

    return (
        <Box sx={{ bgcolor: '#f5f5f5', minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Container component="main" maxWidth="xs" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', boxSizing: 'border-box' }}>
                <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography component="h1" variant="h5">
                        Log in
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                        This is the employee site of Five Spice Indian Grocery!
                    </Typography>

                    <PhoneInput
                        country={'us'}
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        containerStyle={{ width: '100%', marginTop: '20px' }}
                        inputStyle={{ width: '100%', height: '40px' }}
                        onKeyDown={(event) => handleKeyDown(event, handleSendCode)}
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={handleSendCode}
                    >
                        Send Code
                    </Button>
                    {isEmployee && verificationId && (
                        <>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Verification Code"
                                name="code"
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                                autoComplete="code"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <CheckCircleOutline />
                                        </InputAdornment>
                                    ),
                                }}
                                onKeyDown={(event) => handleKeyDown(event, handleVerifyCode)}
                            />
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={handleVerifyCode}
                            >
                                Verify Code
                            </Button>
                        </>
                    )}
                    {error && <Alert severity="error" sx={{ width: '100%', mt: 2 }}>{error}</Alert>}
                    <div id="recaptcha-container"></div>
                </Box>
            </Container>

            <Dialog open={openCompleteProfile} onClose={() => setOpenCompleteProfile(false)}>
                <DialogTitle>Complete Your Profile</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please provide your name, email, and a password to complete your profile.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Name"
                        fullWidth
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Email"
                        type="email"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Password"
                        type="password"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {error && <Alert severity="error" sx={{ width: '100%', mt: 2 }}>{error}</Alert>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenCompleteProfile(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleCompleteProfileSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Login;
