import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  TextField, Button, List, ListItem, ListItemText,
  CircularProgress, Alert, Divider, Typography, Box, IconButton
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

const SearchProductDialog = ({ open, onClose, onSelect, api, isLoading, initialSearchTerm, currentPackSize }) => {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm || '');
  const [searchResults, setSearchResults] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    setSearchTerm(initialSearchTerm || '');
  }, [initialSearchTerm]);

  const handleSearch = useCallback(async () => {
    setError(null);
    setSearchResults([]);
    try {
      const results = await api.searchSquareProducts(searchTerm);
      setSearchResults(results);
    } catch (err) {
      console.error('Error searching products:', err);
      setError('Failed to search products. Please try again.');
    }
  }, [api, searchTerm]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSelectItem = (result) => {
    onSelect({
      id: result.productId,
      name: result.itemName,
      categoryId: result.categoryId,
      variations: [{
        id: result.variationId,
        name: result.variationName,
        price_money: {
          amount: result.price * 100,
          currency: 'USD'
        }
      }],
      sku: result.sku,
      packSize: currentPackSize // Retain the current packSize value
    });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Search Square Products</Typography>
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" alignItems="center" mb={2}>
          <TextField
            autoFocus
            margin="dense"
            label="Search"
            fullWidth
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <IconButton onClick={handleSearch} color="primary" disabled={isLoading} sx={{ ml: 2 }}>
            {isLoading ? <CircularProgress size={24} /> : <SearchIcon />}
          </IconButton>
        </Box>
        {error && <Alert severity="error" style={{ marginBottom: '10px' }}>{error}</Alert>}
        <List>
          {searchResults.map((result) => (
            <React.Fragment key={`${result.productId}-${result.variationId}`}>
              <ListItem button onClick={() => handleSelectItem(result)}>
                <ListItemText
                  primary={<Typography variant="subtitle1">{result.itemName} - {result.variationName}</Typography>}
                  secondary={
                    <>
                      <Typography component="span" variant="body2" color="textPrimary">Price: ${result.price.toFixed(2)}</Typography>
                      <br />
                      <Typography component="span" variant="body2" color="textSecondary">SKU: {result.sku}</Typography>
                    </>
                  }
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(SearchProductDialog);
