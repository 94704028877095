import React from 'react';
import { 
  Box, Typography, Grid, Paper, Button, 
  Card, CardContent, CardActions, IconButton 
} from '@mui/material';
import { 
  Description, CloudUpload, Settings, Category, 
  History, Search, Assessment, Help, AccountCircle 
} from '@mui/icons-material';

const DashboardCard = ({ title, value, icon, onClick }) => (
  <Card elevation={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
    <CardContent sx={{ flexGrow: 1 }}>
      <IconButton size="large" color="primary" sx={{ mb: 2 }}>
        {icon}
      </IconButton>
      <Typography variant="h6" component="div" gutterBottom>
        {title}
      </Typography>
      <Typography variant="h4" color="text.secondary">
        {value}
      </Typography>
    </CardContent>
    <CardActions>
      <Button size="small" onClick={onClick}>View Details</Button>
    </CardActions>
  </Card>
);

const ActionButton = ({ icon, text, onClick }) => (
  <Button
    variant="outlined"
    startIcon={icon}
    onClick={onClick}
    fullWidth
    sx={{ justifyContent: 'flex-start', mb: 1 }}
  >
    {text}
  </Button>
);

const Dashboard = ({
  handleOpenAllInvoices,
  handleOpenSettings,
  handleOpenCategories,
  handleOpenRecentActivities,
  handleOpenSearchInvoices,
  handleOpenGenerateReports,
  handleOpenHelpSupport,
  handleOpenUserProfile,
  invoices = []
}) => {
  const pendingInvoices = invoices.filter(invoice => invoice.invoiceStatus === 'pending').length;
  const newInvoices = invoices.filter(invoice => invoice.invoiceStatus === 'new').length;
  const doneInvoices = invoices.filter(invoice => invoice.invoiceStatus === 'done').length;

  

  return (
    <Box sx={{ py: 5 }}>
      <Typography variant="h4" gutterBottom align="center">
        Invoice Management Dashboard
      </Typography>
      
      <Grid container spacing={3} sx={{ mb: 5 }}>
      <Grid item xs={12} md={4}>
          <DashboardCard 
            title="New Invoices" 
            value={newInvoices} 
            icon={<Description color="info" />} 
            onClick={handleOpenAllInvoices}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DashboardCard 
            title="Pending Invoices" 
            value={pendingInvoices} 
            icon={<Description color="warning" />} 
            onClick={handleOpenAllInvoices}
          />
        </Grid>
       
        <Grid item xs={12} md={4}>
          <DashboardCard 
            title="Completed Invoices" 
            value={doneInvoices} 
            icon={<Description color="success" />} 
            onClick={handleOpenAllInvoices}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>Quick Actions</Typography>
            <ActionButton icon={<Description />} text="View All Invoices" onClick={handleOpenAllInvoices} />
            <ActionButton icon={<CloudUpload />} text="Upload a New Invoice" onClick={() => {}} />
            <ActionButton icon={<Search />} text="Search Invoices" onClick={handleOpenSearchInvoices} />
            <ActionButton icon={<Assessment />} text="Generate Reports" onClick={handleOpenGenerateReports} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>System</Typography>
            <ActionButton icon={<Settings />} text="Settings" onClick={handleOpenSettings} />
            <ActionButton icon={<Category />} text="View Categories" onClick={handleOpenCategories} />
            <ActionButton icon={<History />} text="Recent Activities" onClick={handleOpenRecentActivities} />
            <ActionButton icon={<Help />} text="Help & Support" onClick={handleOpenHelpSupport} />
            <ActionButton icon={<AccountCircle />} text="User Profile" onClick={handleOpenUserProfile} />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;