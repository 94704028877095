import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// Create a context for the authentication state
export const AuthContext = createContext();

// Custom hook to use the auth context
export const useAuth = () => useContext(AuthContext);

// Auth provider component
export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const auth = getAuth();

        // Listener for authentication state changes
        const unsubscribe = onAuthStateChanged(auth, user => {
            setCurrentUser(user);
            setLoading(false);

            // Handle token refresh
            if (user) {
                user.getIdToken(true).then((token) => {
                    console.log('Token refreshed:', token);
                }).catch((error) => {
                    console.error('Error refreshing token:', error);
                });
            }
        });

        // Cleanup function to unsubscribe from the listener when the component unmounts
        return unsubscribe;
    }, []);

    // Periodically refresh the token
    useEffect(() => {
        const intervalId = setInterval(async () => {
            const user = getAuth().currentUser;
            if (user) {
                try {
                    await user.getIdToken(true);
                    console.log('Token refreshed periodically');
                } catch (error) {
                    console.error('Error refreshing token periodically:', error);
                }
            }
        }, 60 * 60 * 1000); // Refresh token every hour

        return () => clearInterval(intervalId);
    }, []);

    const value = { currentUser };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};
