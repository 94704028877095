import React, { useState, useEffect, useCallback, useRef, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Paper, Typography, Grid, Box, TextField, Button, Divider, Snackbar, Alert, AppBar, Toolbar,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import createApi from '../api';
import { format, parseISO } from 'date-fns';
import ProductItem from './ProductItem';
import SearchProductDialog from '../SearchProductDialog';
import SubmitOverviewDialog from '../SubmitOverviewDialog';
import AddInvoiceItemDialog from '../AddInvoiceItemDialog';
import MatchSquareProductDialog from '../MatchSquareProductDialog';

const ProductsList = ({ invoiceData, currentUser, docId, onNavigateToMain }) => {
  const [items, setItems] = useState([]);
  const [vendorName, setVendorName] = useState('');
  const [invoiceDate, setInvoiceDate] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [initialSearchTerm, setInitialSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [incompleteItems, setIncompleteItems] = useState([]);
  const [invoiceStatus, setInvoiceStatus] = useState('new');
  const [isOverviewDialogOpen, setIsOverviewDialogOpen] = useState(false);
  const [isAddItemDialogOpen, setIsAddItemDialogOpen] = useState(false);
  const [isMatchDialogOpen, setIsMatchDialogOpen] = useState(false);
  const [itemToMatchIndex, setItemToMatchIndex] = useState(null);
  const [currentPackSize, setCurrentPackSize] = useState('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const scrollContainerRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const api = createApi(currentUser?.stsTokenManager?.accessToken);

  const navigate = useNavigate();

  useEffect(() => {
    if (invoiceData) {
      setItems(invoiceData.standardized.items || []);
      setVendorName(invoiceData.standardized.vendorName || '');
      setInvoiceNumber(invoiceData.standardized.invoiceNumber || '');
      if (invoiceData.standardized.invoiceDate) {
        const date = new Date(invoiceData.standardized.invoiceDate);
        setInvoiceDate(date.toISOString().split('T')[0]);
      }
      setInvoiceStatus(invoiceData.standardized.invoiceStatus || 'new');
      setTotalAmount(invoiceData.standardized.invoiceTotal || 0);
    }
  }, [invoiceData]);

  const saveScrollPosition = useCallback(() => {
    if (scrollContainerRef.current) {
      setScrollPosition(scrollContainerRef.current.scrollTop);
    }
  }, []);

  useLayoutEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollPosition;
    }
  }, [scrollPosition]);

  const isItemComplete = (item) => {
    return (
      item.description &&
      item.quantity &&
      item.packSize &&
      item.unitPrice &&
      item.squareUp?.itemId
    );
  };

  const handleUpdateItem = useCallback(async ({ invoiceId, itemIndex, updatedItemData }) => {
    if (invoiceStatus === 'done') {
      console.log('Invoice is done, cannot update items');
      return;
    }

    saveScrollPosition();
    setIsLoading(true);
    setError(null);
    try {
      const result = await api.updateItem(invoiceId, itemIndex, updatedItemData);

      await api.updateProductPrice(updatedItemData.squareUp.variationId, updatedItemData.squareUp.price);
      
      setItems(prevItems => {
        const newItems = [...prevItems];
        newItems[itemIndex] = result.updatedItemTableDoc;
        return newItems;
      });
    } catch (err) {
      console.error('Error updating item:', err);
      setError('Failed to update item. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [api, invoiceStatus, saveScrollPosition]);

  const handleUpdatePrice = useCallback(async (variationId, price) => {
    if (invoiceStatus === 'done') {
      console.log('Invoice is done, cannot update price');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      await api.updateProductPrice(variationId, price);
      setError('Price successfully updated in Square');
    } catch (err) {
      console.error('Error updating price in Square:', err);
      setError('Failed to update price in Square. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [api, invoiceStatus]);

  const handleOpenSearch = useCallback(({ itemIndex, initialSearchTerm, currentPackSize }) => {
    console.log(`handleOpenSearch: Function called with parameters:`, { itemIndex, initialSearchTerm, currentPackSize });
  
    if (invoiceStatus === 'done') {
      console.log('handleOpenSearch: Invoice is done, cannot open search');
      return;
    }
  
    console.log(`handleOpenSearch: Setting selectedItemIndex to ${itemIndex}`);
    setSelectedItemIndex(itemIndex);
  
    console.log(`handleOpenSearch: Setting initialSearchTerm to "${initialSearchTerm}"`);
    setInitialSearchTerm(initialSearchTerm);
  
    console.log(`handleOpenSearch: Setting currentPackSize to "${currentPackSize}"`);
    setCurrentPackSize(currentPackSize);
  
    console.log('handleOpenSearch: Opening search dialog');
    setIsSearchDialogOpen(true);
  
    console.log('handleOpenSearch: Function completed');
  }, [invoiceStatus]);

  const handleConfirmSubmitToSquare = async () => {
    setIsLoading(true);
    setError(null);
    try {
      // Prepare the invoice data from the component's state
      const invoiceData = {
        docId,
        vendorName,
        invoiceDate,
        invoiceNumber,
        totalAmount,
        items: items.map(item => ({
          ...item,
          unitPrice: parseFloat(item.unitPrice),
          quantity: parseInt(item.quantity, 10),
          packSize: parseInt(item.packSize, 10) || 1
        }))
      };
  
      // Submit the invoice to Square
      await api.submitInvoiceToSquare(invoiceData, currentUser);
  
      // Update the invoice status
      await api.updateInvoiceStatus(docId, 'done');
  
      setInvoiceStatus('done');
      setError('Invoice successfully submitted to Square and marked as done');
      setIncompleteItems([]);
      setIsOverviewDialogOpen(false);
      navigate('/invoice-entry');
    } catch (err) {
      console.error('Error submitting invoice to Square or updating status:', err);
      setError('Failed to submit invoice to Square or update status. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitToSquare = useCallback(() => {
    if (invoiceStatus === 'done') {
      console.log('Invoice is already submitted and marked as done');
      return;
    }

    const incomplete = items.reduce((acc, item, index) => {
      if (!isItemComplete(item)) {
        acc.push(index);
      }
      return acc;
    }, []);

    if (incomplete.length > 0) {
      setIncompleteItems(incomplete);
      setError(`Please complete all item details before submitting. Incomplete items: ${incomplete.map(i => i + 1).join(', ')}`);
      return;
    }

    setIsOverviewDialogOpen(true);
  }, [items, invoiceStatus]);

  const handleSelectSquareProduct = useCallback((squareProduct) => {
    if (invoiceStatus === 'done') {
      console.log('Invoice is done, cannot select product');
      return;
    }
  
    saveScrollPosition();
    setItems(prevItems => {
      const newItems = [...prevItems];
      const selectedItem = newItems[selectedItemIndex];
  
      const updatedItem = {
        ...selectedItem,
        squareUp: {
          categoryId: squareProduct.categoryId,
          itemId: squareProduct.id,
          itemName: squareProduct.name,
          variationId: squareProduct.variations && squareProduct.variations.length > 0
            ? squareProduct.variations[0].id
            : '',
          variationName: squareProduct.variations && squareProduct.variations.length > 0
            ? squareProduct.variations[0].name
            : '',
          price: squareProduct.variations && squareProduct.variations.length > 0
            ? squareProduct.variations[0].price_money?.amount || 0
            : 0,
          barcode: squareProduct.barcode || squareProduct.sku || ''
        },
        packSize: selectedItem.packSize || currentPackSize // Preserve the current packSize
      };
  
      newItems[selectedItemIndex] = updatedItem;
      return newItems;
    });
  
    setIsSearchDialogOpen(false);
  }, [selectedItemIndex, invoiceStatus, saveScrollPosition, currentPackSize]);
  
  const handleMatchProduct = useCallback((squareProduct) => {
    saveScrollPosition();
    setItems(prevItems => {
      const newItems = [...prevItems];
      const selectedItem = newItems[itemToMatchIndex];
  
      const updatedItem = {
        ...selectedItem,
        squareUp: {
          categoryId: squareProduct.categoryId,
          itemId: squareProduct.id,
          itemName: squareProduct.name,
          variationId: squareProduct.variations && squareProduct.variations.length > 0
            ? squareProduct.variations[0].id
            : '',
          variationName: squareProduct.variations && squareProduct.variations.length > 0
            ? squareProduct.variations[0].name
            : '',
          price: squareProduct.variations && squareProduct.variations.length > 0
            ? squareProduct.variations[0].price_money?.amount || 0
            : 0,
          barcode: squareProduct.barcode || squareProduct.sku || ''
        },
        packSize: selectedItem.packSize // Preserve the current packSize
      };
  
      newItems[itemToMatchIndex] = updatedItem;
      return newItems;
    });
  
    setIsMatchDialogOpen(false);
  }, [itemToMatchIndex, saveScrollPosition]);

  const handleDeleteItem = useCallback(async ({ itemIndex }) => {
    if (invoiceStatus === 'done') {
      console.log('Invoice is done, cannot delete item');
      return;
    }
    saveScrollPosition();
    setIsLoading(true);
    setError(null);
    try {
      await api.deleteItem(docId, itemIndex);
      setItems(prevItems => prevItems.filter((_, i) => i !== itemIndex));
      setIncompleteItems(prevIncomplete => prevIncomplete.filter(i => i !== itemIndex).map(i => i > itemIndex ? i - 1 : i));
      setError('Item successfully deleted');
      
      // Check if this was the last item
      if (items.length === 1) {
        // If it was the last item, delete the entire invoice
        await api.deleteInvoice(docId);
        setError('Last item deleted. Invoice has been removed.');
        // Navigate back to the dashboard
        onNavigateToMain();
      }
    } catch (err) {
      console.error('Error deleting item:', err);
      setError(`Failed to delete item: ${err.message}. Please try again.`);
    } finally {
      setIsLoading(false);
    }
  }, [api, docId, invoiceStatus, saveScrollPosition, items.length, onNavigateToMain]);

  const handleDeleteInvoice = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await api.deleteInvoice(docId);
      setError('Invoice successfully deleted');
      onNavigateToMain(); // Use the prop to navigate back
    } catch (err) {
      console.error('Error deleting invoice:', err);
      setError('Failed to delete invoice. Please try again.');
    } finally {
      setIsLoading(false);
      setIsDeleteDialogOpen(false);
    }
  };

  const handleAddItem = useCallback((newItem) => {
    saveScrollPosition();
    setItems(prevItems => [...prevItems, newItem]);
    setIsAddItemDialogOpen(false);
    setItemToMatchIndex(items.length);
    setIsMatchDialogOpen(true);
  }, [items.length, saveScrollPosition]);


  const isInvoiceDone = invoiceStatus === 'done';

  return (
    <Paper 
      elevation={3} 
      sx={{ 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column', 
        overflow: 'hidden',
        maxWidth: '100%',
        margin: 'auto'
      }}
    >
      <AppBar position="static" color="default" elevation={0}>
        <Toolbar variant="dense">
          <Typography variant="h6" sx={{ flexGrow: 1 }}>Invoice Details</Typography>
        </Toolbar>
      </AppBar>

      <Box p={2} sx={{ flex: '0 0 auto' }}>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Vendor Name"
              value={vendorName}
              onChange={(e) => setVendorName(e.target.value)}
              disabled={isInvoiceDone}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Invoice Date"
              type="date"
              value={invoiceDate}
              onChange={(e) => setInvoiceDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              inputProps={{ max: format(new Date(), 'yyyy-MM-dd') }}
              disabled={isInvoiceDone}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Invoice Number"
              value={invoiceNumber}
              onChange={(e) => setInvoiceNumber(e.target.value)}
              disabled={isInvoiceDone}
              variant="outlined"
              size="small"
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: 2 }} />

        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6">Products ({items.length})</Typography>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmitToSquare}
              disabled={isLoading || isInvoiceDone}
              startIcon={<SendIcon />}
              sx={{ mr: 1 }}
            >
              Submit to Square
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setIsAddItemDialogOpen(true)}
              disabled={isInvoiceDone}
              startIcon={<AddIcon />}
              sx={{ mr: 1 }}
            >
              Add Item
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => setIsDeleteDialogOpen(true)}
              disabled={isLoading || isInvoiceDone}
              startIcon={<DeleteIcon />}
            >
              Delete Invoice
            </Button>
          </Box>
        </Box>
      </Box>

      <Box 
        ref={scrollContainerRef}
        sx={{ 
          flex: '1 1 auto', 
          overflowY: 'auto', 
          px: 2,
        }}
      >
        {items.map((item, index) => (
          <ProductItem
            key={index}
            item={item}
            invoiceId={docId}
            itemIndex={index}
            onSearch={handleOpenSearch}
            onUpdate={handleUpdateItem}
            onUpdatePrice={handleUpdatePrice}
            onDelete={handleDeleteItem}
            isIncomplete={incompleteItems.includes(index)}
            disabled={isInvoiceDone}
          />
        ))}
      </Box>

      <SearchProductDialog
        open={isSearchDialogOpen}
        onClose={() => setIsSearchDialogOpen(false)}
        onSelect={handleSelectSquareProduct}
        api={api}
        isLoading={isLoading}
        initialSearchTerm={initialSearchTerm}
      />

      <AddInvoiceItemDialog
        open={isAddItemDialogOpen}
        onClose={() => setIsAddItemDialogOpen(false)}
        onAddItem={handleAddItem}
        currentUser={currentUser}
        api={api}
        docId={docId}
      />

<MatchSquareProductDialog
  open={isMatchDialogOpen}
  onClose={() => setIsMatchDialogOpen(false)}
  onMatchProduct={handleMatchProduct}
  currentUser={currentUser}
  initialSearchTerm={items[itemToMatchIndex]?.description || ''}
  currentPackSize={items[itemToMatchIndex]?.packSize || ''}
/>

      <SubmitOverviewDialog
        open={isOverviewDialogOpen}
        onClose={() => setIsOverviewDialogOpen(false)}
        items={items}
        invoiceTotal={totalAmount}
        onConfirm={handleConfirmSubmitToSquare}
      />

      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this invoice? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteInvoice} color="error">Delete</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
      >
        <Alert
          onClose={() => setError(null)}
          severity={error && error.toLowerCase().includes('successfully') ? 'success' : 'error'}
        >
          {error}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default React.memo(ProductsList);