import React, { useState, useEffect } from 'react';
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, CircularProgress, Box, Grid, IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const DEFAULT_MARGIN_RANGES = [
  { min: 0, max: 1, margin: 0.45 },
  { min: 1, max: 3, margin: 0.29 },
  { min: 3, max: 5, margin: 0.28 },
  { min: 5, max: 9, margin: 0.25 },
  { min: 9, max: 12, margin: 0.23 },
  { min: 12, max: Infinity, margin: 0.20 }
];

const UpdateMarginRanges = ({ open, onClose, category, initialMarginRanges, onSave, isLoading }) => {
  const [marginRanges, setMarginRanges] = useState(initialMarginRanges || DEFAULT_MARGIN_RANGES);
  const [error, setError] = useState(null);

  useEffect(() => {
    setMarginRanges(initialMarginRanges || DEFAULT_MARGIN_RANGES);
  }, [initialMarginRanges]);

  const handleRangeChange = (index, field, value) => {
    const newRanges = [...marginRanges];
    newRanges[index][field] = value === '' ? '' : parseFloat(value);
    setMarginRanges(newRanges);
  };

  const addNewRange = () => {
    setMarginRanges([...marginRanges, { min: 0, max: '', margin: 0 }]);
  };

  const removeRange = (index) => {
    const newRanges = marginRanges.filter((_, i) => i !== index);
    setMarginRanges(newRanges);
  };

  const validateRanges = () => {
    for (let i = 0; i < marginRanges.length; i++) {
      const { min, max } = marginRanges[i];
      if (min >= max && max !== '') {
        setError(`Range ${i + 1} is invalid: Min cost should be less than Max cost.`);
        return false;
      }
      if (i > 0 && marginRanges[i - 1].max >= min) {
        setError(`Range ${i + 1} overlaps with the previous range.`);
        return false;
      }
    }
    setError(null);
    return true;
  };

  const handleSave = async () => {
    if (validateRanges()) {
      try {
        await onSave(marginRanges);
      } catch (error) {
        setError('Failed to save margin ranges.');
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Update Margin Ranges for {category?.name}</DialogTitle>
      <DialogContent>
        {error && (
          <Box mb={2}>
            <Typography color="error">{error}</Typography>
          </Box>
        )}
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Typography variant="subtitle1">Min Cost</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="subtitle1">Max Cost</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle1">Margin %</Typography>
          </Grid>
        </Grid>
        {marginRanges.map((range, index) => (
          <Grid container spacing={2} alignItems="center" key={index} mb={2}>
            <Grid item xs={5}>
              <TextField
                label="Min Cost"
                type="number"
                value={range.min}
                onChange={(e) => handleRangeChange(index, 'min', e.target.value)}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                label={index === marginRanges.length - 1 ? "Max Cost (optional)" : "Max Cost"}
                type="number"
                value={range.max === Infinity ? '' : range.max}
                onChange={(e) => handleRangeChange(index, 'max', e.target.value === '' ? Infinity : parseFloat(e.target.value))}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={1.5}>
              <TextField
                label="Margin %"
                type="number"
                value={range.margin}
                onChange={(e) => handleRangeChange(index, 'margin', e.target.value)}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={0.5}>
              <Tooltip title="Remove Range" arrow>
                <span>
                  <IconButton onClick={() => removeRange(index)} disabled={marginRanges.length === 1}>
                    <RemoveIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        ))}
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button onClick={addNewRange} startIcon={<AddIcon />}>
            Add New Range
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} /> : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateMarginRanges;
