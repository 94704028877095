// src/firebaseAuthFunctions.js
import {
    getAuth, updateProfile, updateEmail, updatePassword, sendEmailVerification, reauthenticateWithCredential,
    EmailAuthProvider, linkWithPopup, GoogleAuthProvider, unlink, linkWithCredential
} from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export const updateUserProfile = async (displayName, photoURL) => {
    const auth = getAuth();
    try {
        await updateProfile(auth.currentUser, { displayName, photoURL });
        console.log("Profile updated successfully.");
    } catch (error) {
        console.error("Error updating profile:", error);
        throw error;
    }
};

export const updateUserEmail = async (newEmail) => {
    const auth = getAuth();
    try {
        await sendEmailVerification(auth.currentUser);
        console.log("Verification email sent.");
        alert("A verification email has been sent to your new email address. Please verify it before updating.");
    } catch (error) {
        console.error("Error sending verification email:", error);
        throw error;
    }

    try {
        const credential = EmailAuthProvider.credential(auth.currentUser.email, prompt('Please enter your password to verify your identity.'));
        await reauthenticateWithCredential(auth.currentUser, credential);
        await updateEmail(auth.currentUser, newEmail);
        console.log("Email updated successfully.");
    } catch (error) {
        console.error("Error updating email:", error);
        throw error;
    }
};

export const updateUserPassword = async (newPassword) => {
    const auth = getAuth();
    try {
        await updatePassword(auth.currentUser, newPassword);
        console.log("Password updated successfully.");
    } catch (error) {
        console.error("Error updating password:", error);
        throw error;
    }
};

export const linkAuthProvider = async (provider) => {
    const auth = getAuth();
    try {
        const result = await linkWithPopup(auth.currentUser, provider);
        console.log("Provider linked successfully:", result.user);
    } catch (error) {
        console.error("Error linking provider:", error);
        throw error;
    }
};

export const linkEmailProvider = async (email, password) => {
    const auth = getAuth();
    const credential = EmailAuthProvider.credential(email, password);
    try {
        const result = await linkWithCredential(auth.currentUser, credential);
        console.log("Email provider linked successfully:", result.user);
    } catch (error) {
        console.error("Error linking email provider:", error);
        throw error;
    }
};

export const sendVerificationEmail = async () => {
    const auth = getAuth();
    try {
        await sendEmailVerification(auth.currentUser);
        console.log("Verification email sent successfully.");
    } catch (error) {
        console.error("Error sending verification email:", error);
        throw error;
    }
};

export const uploadProfilePicture = async (file) => {
    const storage = getStorage();
    const auth = getAuth();
    const storageRef = ref(storage, `profile_pictures/${auth.currentUser.uid}`);
    try {
        await uploadBytes(storageRef, file);
        const photoURL = await getDownloadURL(storageRef);
        await updateUserProfile(auth.currentUser.displayName, photoURL);
        return photoURL;
    } catch (error) {
        console.error("Error uploading profile picture:", error);
        throw error;
    }
};

export const checkLinkedProviders = () => {
    const auth = getAuth();
    const providers = auth.currentUser.providerData.map(provider => provider.providerId);
    return {
        google: providers.includes(GoogleAuthProvider.PROVIDER_ID),
        email: providers.includes(EmailAuthProvider.PROVIDER_ID),
    };
};

export const unlinkProvider = async (providerId) => {
    const auth = getAuth();
    try {
        await unlink(auth.currentUser, providerId);
        console.log(`${providerId} unlinked successfully.`);
    } catch (error) {
        console.error(`Error unlinking ${providerId}:`, error);
        throw error;
    }
};

export const reauthenticateWithPassword = async (password) => {
    const auth = getAuth();
    const credential = EmailAuthProvider.credential(auth.currentUser.email, password);
    try {
        await reauthenticateWithCredential(auth.currentUser, credential);
        console.log("Password reauthentication successful.");
    } catch (error) {
        console.error("Error reauthenticating with password:", error);
        throw error;
    }
};
